<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    class="elevation-1"
    hide-default-footer
  ></v-data-table>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "id",
          sortable: false,
          value: "id",
        },
        { text: "Date", value: "date", sortable: false },
        { text: "Link", value: "link", sortable: false },
        { text: "Charge", value: "charge", sortable: false },
        { text: "Start", value: "start", sortable: false },
        { text: "Count", value: "count", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Service", value: "service", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Remains", value: "Remains", sortable: false },
      ],
      desserts: [
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service:
            "lorem50 hello ml gvfuyigkj ugfudtyvg ugfdyvfgv ohiyuftyb fergfh ",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
        {
          id: 9597863,
          date: "2022-03-02 11:21:01",
          link: "https://www.facebook.com/100011655529829/posts/1622329791498856/?app=fbl",
          charge: "326.605",
          start: 0,
          count: 500,
          quantity: "https://instagram.com/lllll",
          service: "🦉 NIGERIAN FOLLOWERS ( 1000 ACTIVE FOLLOWERS )",
          status: "Completed",
          Remains: 0,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
